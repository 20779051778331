<template>
    <div class="aboutUsWrap">
        <Header></Header>
        <div class="aboutUs">
            <div class="rightContent">
                <!-- <div slot="text" class="textWrap" v-html="$t('oa.aboutTIps')"></div> -->
                <div class="title" v-html="$t('oa.homeTitle')"></div>
                <div class="text" v-html="$t('oa.aboutUsContent')"></div>
            </div>
        </div>
    </div>
</template>
<script>
import Header from "@/components/header.vue";
import topBg from "@/components/topBg.vue";
export default {
    name: "aboutUs",
    components: {
        Header,
        topBg,
    },
};
</script>
<style lang="scss" scoped>
.aboutUsWrap {
    
    .aboutUs {
        position: relative;
        width: 100%;
        height: 100vh;
        margin: 0 auto;
        background: url("../../assets/images/contentBG.jpg") center center / cover;
        justify-content: center;
        align-items: center;
        display: flex;
        .textWrap {
            width: 100%;
            text-align: center;
            color: #fff;
            font-size: 38px;
            margin-bottom: 100px;
            // font-family: FrutigerLTStd;
        }
        .rightContent {
            padding-bottom: 100px;
            min-height: 500px;
            text-align: left;
            color: #fff;
            position: relative;
            z-index: 20;
            .title {
                font-size: 30px;
                margin-bottom: 20px;
                text-align: center;
                font-weight: bold;
                /deep/span.frist {
                    font-size: 30px;
                }
            }
            .text {
                width: 820px;
                margin: 0 auto;
                line-height: 40px;
                font-size: 15px;
                // word-break: break-all;
                word-break: normal;
            }
        }
    }
    .aboutUs::before{
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        background: rgba(0,0,0,0.4);
    }
}
</style>